import { useState, useEffect } from 'react';
import { schemas } from './schemas';
import { useToast } from '@chakra-ui/react';
const useSchemas = () => {
    const [schemasData, setSchemasData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSchemas = async () => {
            setLoading(true);
            try {
                const data = await schemas.getSchemas();
                setSchemasData(data); // Update state with the fetched data
            } catch (err) {
                setError(err); // Handle any errors that occur during the fetch
            } finally {
                setLoading(false); // Ensure loading is set to false after fetch completes
            }
        };
        fetchSchemas();
    }, []); // Ensures this effect runs only once on mount

    return { schemasData, loading, error };
};

const useToasty = async (title, description, status = "success", position = "top") => {
    const toast = useToast();
    return toast({
        title: title,
        description: description,
        status: status,
        duration: 5000,
        isClosable: true,
        position: position,
      });
}
export { useSchemas, useToasty };
