import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { chakra, Box } from '@chakra-ui/react';

// Wrap DatePicker with Chakra's Box component for styling
const ChakraStyledDatePicker = chakra(DatePicker);


const InternalDateBox = (property, callback) => {
    const [startDate, setStartDate] = useState(new Date());
  
    return (
      <Box>
        <ChakraStyledDatePicker
          selected={property}
          onChange={(date) => callback(date)}
          dateFormat="yyyy-MM-dd"
          // Apply Chakra UI styling props directly
          _hover={{
            borderColor: 'blue.300',
          }}
        />
      </Box>
    );
  };

  export default InternalDateBox;