import React, { useEffect, useState } from 'react';
import { useSchemas } from '../../services/core';
import './loginForm.css';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { AuthCache, login } from '../../components/Auth';
import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import { DynamicForm } from '../../components/DynamicForm';
import API from '../../services/apiRequest';
const RegisterPage = () => {
  const navigate = useNavigate();
  const { schemasData, loading, error } = useSchemas();
  const [ token, setToken ] = useState(null);

    useEffect(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('token');
      if (code) {
        API.request(API_ENDPOINTS.validateInviteToken, {token: code}).then(res => {
          if (res.data.validation) {
            setToken(code); 
          } else {
            window.location.href = "/";
          }
        });
      }
    });

    return (
      <Flex minHeight="100vh"
        width="full"
        marginTop="10vh"
        justifyContent="center"
        backgroundColor="gray.800"
      >
        <VStack
          padding={8}
          spacing={4}
          boxShadow="lg"
          borderRadius="lg"
        >

        <img src="Logo_menthorQ.png" alt="menthorQ Logo" style={{ maxWidth: '40vw' }}/>
        <Text fontSize='lg' as='b'>Registration</Text><br/>
        {token && <DynamicForm
          schema={ schemasData?.UserRegistrationModel }
          endpoint={ API_ENDPOINTS.register }
          hide_property={['token']}
          initialValues={{token: token}}
          success_msg='Registration complete!'
          updateCallback={() => {setTimeout(() => {
            window.location.href = '/';
          }, 3000)}}
          post_succes={1}

        />}
      </VStack>
      </Flex>
    );
  };
  
  export default RegisterPage;