import React from 'react';
import { Box, Text, Select, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const TabContent = ({ children }) => children;
TabContent.propTypes = {
    label: PropTypes.string.isRequired,
    renderOn: PropTypes.bool,
    children: PropTypes.node.isRequired
};
TabContent.defaultProps = {
    renderOn: true
};

const NiceTabs = ({ children, ...props }) => {
    return (
        <Box maxW="md">
            <Tabs {...props}>
                <TabList>
                    {React.Children.map(children, (child, index) => child.props.renderOn && (
                        <Tab key={index}>{child.props.label}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {React.Children.toArray(children).filter(child => child.props.renderOn).map((child, index) => (
                        <TabPanel p={4} key={index}>
                            {child.props.children}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Box>
    );
};


const HeavyTabs = ({ layout }) => {

    return (<Box maxW="md">
        <Tabs>
        <TabList>
        {layout.map((tab, index) => (
            <Tab key={index}>{tab.label}</Tab>
        ))}   
        </TabList>
        <TabPanels>
            {layout.filter(tab => tab.renderOn).map((tab, index) => (
                <TabPanel p={4} key={index}>
                {tab.content}
                </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
      </Box>)
}

export default HeavyTabs;
export { TabContent, NiceTabs }; 