import React, { useState, useEffect } from "react";
import { Link, Box, Flex, Text, Button, Stack, MenuButton, Menu, MenuList, MenuItem, MenuGroup, MenuDivider, Image } from "@chakra-ui/react";
import { AuthRoute, AuthCache, LoggedInUser } from '../Auth';
import { Link as RouterLink } from 'react-router-dom'; // Import RouterLink for internal navigation

const NavBar = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Logo
        color={["white", "white", "primary.500", "primary.500"]}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="white"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const NMenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(AuthCache.getAuthStatus());
  const [username, setUsername] = useState(LoggedInUser.getUsername());
  const [accountType, setAccountType] = useState(LoggedInUser.getAccountType());
  useEffect(() => {
    // Subscribe to AuthCache changes
    const unsubscribeAuth = AuthCache.subscribe((newAuthStatus) => {
      setIsLoggedIn(newAuthStatus);
    });

    // Subscribe to LoggedInUser changes
    const unsubscribeUser = LoggedInUser.subscribe((newUsername) => {
      setUsername(newUsername);
    });

    const unsubscribeAccountType = LoggedInUser.subscribeAT((at) => {
      setAccountType(at);
    });
    // Cleanup subscriptions on component unmount
    return () => {
      unsubscribeAuth();
      unsubscribeUser();
      unsubscribeAccountType();
    };
  }, []);
  
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={5}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "row", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <Link as={RouterLink} to="/" style={{ textDecoration: 'none' }}>
              <Button>Home</Button>
          </Link> 
        
        {/**
         * COMMANDS MENU
         */
         (isLoggedIn && accountType == 'admin') && 
          <Link as={RouterLink} to="/commands_admin" style={{ textDecoration: 'none' }}>
            <Button>Commands</Button>
          </Link>
        }

         {/**
         * AUTOPOST MENU
         */
         (isLoggedIn && accountType == 'admin') && 
          <Link as={RouterLink} to="/autopost_admin" style={{ textDecoration: 'none' }}>
            <Button>Autopost</Button>
          </Link>
        }

        {/**
         * MY SERVERS MENU
         */
         isLoggedIn && 
         <Link as={RouterLink} to="/my_servers" style={{ textDecoration: 'none' }}>
           <Button>My Servers</Button>
         </Link>
        }
        
        {
        /**
         * USER TAB MENU
         */
         isLoggedIn ? (
          
          <Menu>
            <MenuButton as={Button} colorScheme='purple'
              href={isLoggedIn ? undefined : "/login"}>
            {isLoggedIn ? (username || "User") : "Login"}
            </MenuButton>
              <MenuList>
                  <>
                    <MenuGroup title='Profile'>
                      <MenuItem as='a' href="/account">My Account</MenuItem>
                      {accountType === "admin" && <MenuItem as='a' href="/admin">Admin Access</MenuItem>}
                      <MenuItem>Payments</MenuItem>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup title='Help'>
                      <MenuItem>Docs</MenuItem>
                      <MenuItem as='a' href="/logout">Logout</MenuItem>
                    </MenuGroup>
                  </>
              </MenuList>
          </Menu>
          ) : (
            // Use RouterLink for navigation without reloading the page
            <Link as={RouterLink} to="/login" style={{ textDecoration: 'none' }}>
              <Button colorScheme='purple'>Login</Button>
            </Link>
          )}
      </Stack>
    </Box>
  );
};

const Logo = (props) => {
    return (
      <Box {...props}>
        <Image src="Logo_menthorQ.png" alt="menthorQ Logo"
             maxWidth={['40vw', '35vw', '20vw', '20vw']}
             height='auto'
             objectFit="cover"
             marginTop='-35px'
             marginBottom='-40px' 
             />
      </Box>
    );
  };

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={8}
      p={8}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default NavBar;