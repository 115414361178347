import React, { useEffect, useState } from 'react';
import API from '../../services/apiRequest';
import { Box, Text, Switch, FormControl, FormLabel, SimpleGrid, Select } from '@chakra-ui/react';

const SwitchList = ({ list = null, callback = null, children, ...props }) => {
    const [listData, setListData] = useState([]);
    
    useEffect(() => {
        setListData(list);
    }, [list]);

    const handleSwitchChange = (event) => {
        const updatedList = { ... listData };
        updatedList[event.target.id].is_active = event.target.checked;
        setListData(updatedList);

        if (callback) {
            callback(event, updatedList[event.target.id]);
        }
    }

    return (
        <Box>
            {Object.keys(listData).length > 0 &&
            <FormControl as={SimpleGrid} columns={{ base: 2, lg: 2 }} alignItems='center'>
                {Object.keys(listData).map((item, index) => (
                    <Box key={item}>
                        <FormLabel htmlFor={item}>
                            {item}
                        </FormLabel>
                        <Switch id={item} 
                            checked={listData[item].is_active}
                            isChecked={listData[item].is_active}
                            onChange={handleSwitchChange} />
                        <br />
                    </Box>
                ))}
            </FormControl>
            }
        </Box>
    );
};

const SwitchListWithSelection = ({ list_endpoint = null, callback = null, children, ...props }) => {
    const [listData, setListData] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    useEffect(() => {
        if (list_endpoint) {
            API.request(list_endpoint).then(res => {
                setListData(res.data);
                setSelectedItemIndex(Object.keys(res.data)[0]);
            });
        }

    }, [list_endpoint]);

    const handleSelectChange = (event) => {
        setSelectedItemIndex(event.target.value);
    }

    return (
        <Box>
            {Object.keys(listData).length > 0 &&
                <Select value={selectedItemIndex} onChange={handleSelectChange}>
                    {Object.keys(listData).map((key, index) => (
                        <option key={key} value={key}>
                            {key}
                        </option>
                    ))}
                </Select>
            }
            <br />
            {Object.keys(listData).length > 0 && 
                <SwitchList callback={callback} list={listData[selectedItemIndex]} />
            }
        </Box>
    )


};
export { SwitchList, SwitchListWithSelection };

