import React, { useState, useEffect } from 'react';
import {
    Box, Text, Select,
    systemProps
} from '@chakra-ui/react';

/**
 * 
 * @param {function} select_display_format the callback. passes in variable `item`
 * @returns 
 */
const DynamicSelect = ({ select_data, selectedIndexState, select_display_property = 'name',
    select_text = 'Select Item', ...props }) => {

    //const toast = useToast();
    const [itemData, setItemData] = useState(null);
    const [localSelectedItemIndex, setLocalSelectedItemIndex] = useState({ index: 0, item_id: 1 });

    const [selectedItemIndex, setSelectedItemIndex] = selectedIndexState || [localSelectedItemIndex, setLocalSelectedItemIndex];


    useEffect(() => {
        setItemData(select_data);
        if (!selectedItemIndex) {
            setSelectedItemIndex({ index: 0, value: 1 });
        }
    });


    const handleEditMenuChange = (value) => {
        setSelectedItemIndex({ index: value, item_id: itemData[value].id });
    }


    return (
        <Box>
            {(itemData) &&
                <Select value={selectedItemIndex.index} onChange={(e) => handleEditMenuChange(e.target.value)}>
                    {itemData && itemData.map((item, index) => (
                        <option key={index} value={index.toString()}>
                            {item[select_display_property]}
                        </option>
                    ))}
                </Select>
            }
        </Box>
    )
}

export default DynamicSelect;