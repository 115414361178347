import React, { useEffect, useState } from 'react';
import { DynamicForm, DynamicFormWithSelect } from '../../components/DynamicForm';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { useSchemas } from '../../services/core';
import { Box, Text, Button, Select, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import HeavyTabs from '../../components/HeavyTabs';
import API from '../../services/apiRequest';

const MyAccountContainer = () => {
    const { schemasData, loading, error } = useSchemas();
    const [userData, setUserData] = useState(null);

    const getUser = async () => {
      await API.request(API_ENDPOINTS.getCurrentUser).then(res => {
          const qdata = res.data;
          setUserData(qdata);
      });
  };

    useEffect(() => {
      getUser();
    }, []);


    return (
      
      <Box maxW="lg">
        <HeavyTabs layout={
          [
            // Edit User
            {
                label: 'Edit User',
                renderOn: userData,
                content: (
                  <DynamicForm
                  initialValues={ userData }
                  schema={ schemasData?.UserEditModel }
                  endpoint={ API_ENDPOINTS.updateUser }
                  success_msg='User updated!'
                  updateCallback={getUser}
                />)
            },
            // Change Password
            {
                label: 'Change Password',
                renderOn: schemasData,
                content: (
                  <DynamicForm
                  schema={ schemasData?.PasswordResetModel }
                  endpoint={ API_ENDPOINTS.passwordReset }
                  success_msg='Password reset!'
                />)
            }
          ]
        }
        />
      </Box>
    )
  };
  
  export default MyAccountContainer;