import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthRoute } from './components/Auth';

import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/Register';
import Home from './pages/Home';
import LogoutPage from './pages/Logout';
import AdminPage from './pages/AdminPage';
import CommandsContainer from './pages/Commands';
import AutopostContainer from './pages/Autopost';
import MyServersContainer from './pages/MyServers';
import MyAccountContainer from './pages/MyAccount';
import Layout from './Layout';


function App() {
  return (
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<AuthRoute publicOnly={true}> <LoginPage /> </AuthRoute>} />
            <Route path="/register" element={<AuthRoute publicOnly={true}> <RegisterPage /> </AuthRoute>} />
            <Route path="/" element={<AuthRoute> <Home /> </AuthRoute>}/>
            <Route path="/logout" element={<AuthRoute> <LogoutPage /> </AuthRoute>}/>
            <Route path="/admin" element={<AuthRoute adminOnly={true}> <AdminPage /> </AuthRoute>}/>
            <Route path="/commands_admin" element={<AuthRoute adminOnly={true}> <CommandsContainer /> </AuthRoute>}/>
            <Route path="/autopost_admin" element={<AuthRoute adminOnly={true}> <AutopostContainer/> </AuthRoute>}/>
            <Route path="/my_servers" element={<AuthRoute> <MyServersContainer/> </AuthRoute>}/>
            <Route path="/account" element={<AuthRoute> <MyAccountContainer/> </AuthRoute>}/>
            
          </Routes>
        </Layout>
      </Router>
  );
}

export default App;
