import React from 'react';
import { Select, CreatableSelect } from 'chakra-react-select';

function DynamicSelectBox({ fieldValue, fieldValueOptions, property, propertyKey, handleSelectItemChange, readOnly = false}) {
  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  // Helper function to check if a value is object-like
  const isObjectLike = (value) => typeof value === 'object' && value !== null && !Array.isArray(value);
  // Logic to prepare options
  const options = [{
    label: propertyKey,
    options: isObjectLike(fieldValueOptions) ?
    Object.entries(fieldValueOptions).map(([key, val]) => ({
      label: `${key} (${val})`, // Adjust as needed
      value: property.dynamic_list?.sub_field_type == 'key' ? key : val 
    }))
    :
    fieldValueOptions.map(item => ({
      label: item,
      value: item
    }))
  }];

  // Handle select all button click
  const handleSelectAll = () => {
    const allOptions = options[0].options;
    handleSelectItemChange(allOptions, propertyKey);
  };

  // Determine if the select should be creatable
  const isCreatable = property.dynamic_list?.creatable;
  // Common props for both Select and CreatableSelect
  
  if (typeof(fieldValue) === 'string' && fieldValue.length > 0) {
    fieldValue = {'label': fieldValue, 'value': fieldValue};
  } 
  const selectProps = {
    value: fieldValue && (fieldValue.length || Object.keys(fieldValue).length) > 0 ? fieldValue : null,
    isMulti: property.dynamic_list?.multi || false,
    propertyKey: propertyKey,
    onChange: (e) => handleSelectItemChange(e, propertyKey),
    options: options,
    closeMenuOnSelect: false,
    placeholder: `Select ${property.dynamic_list?.multi ? 'multiple' : 'a'} value${property.dynamic_list?.multi ? 's' : ''}`,
  };

  // Conditionally render Select or CreatableSelect based on isCreatable
  return (
    <div>
      {isCreatable ? (
        <CreatableSelect isReadOnly={readOnly} {...selectProps} />
      ) : (
        <Select isReadOnly={readOnly} {...selectProps} />
      )}
      <br/>
      {property.dynamic_list?.multi && !readOnly && (
        <button type="button" onClick={handleSelectAll} className="chakra-button css-1e89954">Select All Commands</button>
      )}
    </div>
  );
}

export default DynamicSelectBox;