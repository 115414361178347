import React from 'react';
import Navbar from './components/Navbar'; // Adjust the path as necessary

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      {/* Footer or any other global components can go here */}
    </>
  );
};

export default Layout;
