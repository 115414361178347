import React from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, 
    AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton, Button,
    useDisclosure, useToast
} from '@chakra-ui/react';

import API from '../../services/apiRequest';

const DynamicAlertDialog = ({ endpoint, button_text='Delete', header_text='Delete this item?', 
    body_text='This action cannot be undone', updateCallback = false,
    submit_text='submit', success_msg, obj_id, ...props }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancel = React.useRef();
    const toast = useToast();

    

    const handleSubmit = async (e) => {
        let newEndpoint = { ...endpoint };
        if (!obj_id) {
            toast({
                title: "Error",
                description: "No object found",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
            return;
        }
        newEndpoint.url += `/${obj_id}`;
        await API.request(newEndpoint).then(res => {
            const status = res.data?.success ?? true;
            if (!status) {
                toast({
                    title: "Error",
                    description: "An error has occured.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top", 
                });
            }
            toast({
                title: "Success",
                description: success_msg,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top", 
            });
            // reload data
            if (updateCallback) {
                updateCallback();
            }
            onClose();
        }).catch(err => {
            const err_msg = err.response.data?.detail.detail ?? err.response.data?.detail ?? err.data?.detail ?? err.response.data?.detail.success ?? "An error has occured."; 
            console.log("Error message: ", err)
            toast({
                title: "Error",
                description: err_msg,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        });
    };

    return (
        <>
            <Button size="sm" mx="auto" display="block" onClick={onOpen}>{button_text}</Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancel}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>{header_text}</AlertDialogHeader>
                        <AlertDialogBody>{body_text}</AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancel} onClick={onClose}>Cancel</Button>
                            <Button colorScheme='red' onClick={handleSubmit} ml={3}>{submit_text}</Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
} 

export { DynamicAlertDialog };