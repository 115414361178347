import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  Text
} from '@chakra-ui/react';
import { marked } from 'marked';

const DynamicAccordion = ({ items }) => {
  return (
    <Accordion allowToggle>
      {items.map((item, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text as='b'>{item.title}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text dangerouslySetInnerHTML={{__html: item.data.replace(/\n/g, "<br/>")}}/>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const MarkdownAccordion = ({ markdown }) => {

    const dedent = (text) => text.replace(/^\s{4}/gm, '');

    const createMarkup = () => {
        const safeMarkdown = markdown ?? '';
        return { __html: marked(dedent(safeMarkdown)).replace(/\n/g, "<br/><br/>")};
    };
    
    return <div dangerouslySetInnerHTML={createMarkup()} />;
};
    


export default DynamicAccordion;
export {MarkdownAccordion};
