import React from 'react';
import { Formik, Field, Form } from 'formik';
import axios from 'axios';
import './loginForm.css';
import { useNavigate } from 'react-router-dom';
import { AuthCache, login } from '../../components/Auth';
import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';

const LoginPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (values) => {

    let res = await login(values, navigate);
    if (res) {
      toast({
        title: "Error",
        description: res,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top", // or "bottom", depending on your preference
      });
    }
  };

  return (
    <Flex minHeight="100vh"
      width="full"
      marginTop="10vh"
      justifyContent="center"
      backgroundColor="gray.800"
    >
      <VStack
        padding={8}
        spacing={4}
        boxShadow="lg"
        borderRadius="lg"
      >

        <img src="Logo_menthorQ.png" alt="menthorQ Logo" style={{ maxWidth: '40vw' }} />
        <Text fontSize='lg' as='b'>Login to Portal</Text><br />
        <Formik
          initialValues={{
            username: '',
            password: '',
            user_agent: 'N/A',
          }}

          onSubmit={handleSubmit}
        >
          <Form>
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="username" className='label'><Text as='b'>Username</Text></label>
              <Field id="username" name="username" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="password" className='label'><Text as='b'>Password</Text></label>
              <Field id="password" name="password" type="password" />
            </div>

            <button type="submit" className='button'>Log in</button>
          </Form>
        </Formik>
      </VStack>
    </Flex>
  );
};

export default LoginPage;