import React, { useState, useEffect, useCallback } from 'react';

import { Box, Center, Text, Flex, Square, Spinner, Grid, GridItem } from '@chakra-ui/react';
import { API_ENDPOINTS } from '../../services/apiConfig';
import API from '../../services/apiRequest';
import './loginForm.css';
import { DynamicTable } from '../../components/DynamicTable';

const Home = () => {
  const [myServersReadData, setMyServersReadData] = useState(null);
  const [serverIDs, setServerIDs] = useState(null);
  const [myServerStats, setMyServerStats] = useState({});

  const getMyServersRead = useCallback(async () => {
    await API.request(API_ENDPOINTS.getMyDiscordServersRead).then(res => {
      const qdata = res.data;
      setMyServersReadData(qdata);
      if (qdata.length > 0) {
        const ids = qdata.map(item => [item.id, item.nickname]);
        setServerIDs(ids);
      }
    });
  }, [API, API_ENDPOINTS.getMyDiscordServersRead]); // Add necessary dependencies or leave empty if they're guaranteed to be static

  // This effect fetches server read data once component mounts
  useEffect(() => {
    getMyServersRead();
  }, [getMyServersRead]);

  // Separate effect to fetch server stats once server IDs are available
  useEffect(() => {
    if (!serverIDs) {
      return;
    }

    serverIDs.forEach((id) => {
      let newEndpoint = { ...API_ENDPOINTS.getMyServerStats, url: `${API_ENDPOINTS.getMyServerStats.url}/${id[0]}/${1}` };
      API.request(newEndpoint).then(res => {
        const qdata = res.data;
        setMyServerStats(prev => ({ ...prev, [id[0]]: Object.values(qdata) }));
      });
    }

    );

  }, [serverIDs]); // Depend on serverIDs to trigger this effect

  return (
    <Flex color='white'>
      <Center w='200px' bg='white.500'>

      </Center>
      <Box flex='1' bg='gray.350'>
        <Box >
          <Center>
            <Box bg='green'>
              {myServersReadData &&
                <Text as='b'>You have {myServersReadData?.length ?? 0} servers.</Text>
              }
            </Box>
          </Center>

          {(serverIDs && Object.keys(myServerStats).length > 0) ? serverIDs.map(item => {
            return (
              <DynamicTable
                key={item}
                data={myServerStats}
                title={`Server Stats for ${item[1]}`} />
            );
          }) : null}

        </Box>
      </Box>
      <Center w='200px' bg='white.500'>

      </Center>
    </Flex>
  );
};

export default Home;