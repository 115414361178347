import React, { useEffect, useState } from 'react';
import { DynamicForm, DynamicFormWithSelect } from '../../components/DynamicForm';
import { RequestForm } from '../../components/RequestsForm';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { useSchemas } from '../../services/core';
import { Box, HStack, Text, Button, Select, Tabs, TabList, TabPanels, Tab, TabPanel, Heading, Center } from '@chakra-ui/react';
import HeavyTabs, { NiceTabs, TabContent } from '../../components/HeavyTabs';
import API from '../../services/apiRequest';

const AdminPage = () => {
  const { schemasData, loading, error } = useSchemas();
  const [usersData, setUsersData] = useState(null);
  const [usersSettings, setUsersSettings] = useState(null);
  const [discordServersAdminEdit, setDiscordServersAdminEdit] = useState(null);
  const [period, setPeriod] = useState('today');

  //Index and id of the selected server:
  const [selectedServerData, setSelectedServerData] = useState(null);

  //Index and id of the selected role:
  const [selectedRoleData, setSelectedRoleData] = useState({ index: null, server_id: null });

  //Guild ID of selected server for Bot Commands Download:
  const [selectedServerGuildID, setSelectedServerGuildID] = useState('');

  //All roles that belong to the selected server:
  const [myRolesReadData, setMyRolesReadData] = useState(null);

  // Name of selected role for Bot Commands Download:
  const [selectedRoleName, setSelectedRoleName] = useState('');

  const getUsers = async () => {
    await API.request(API_ENDPOINTS.getAllUsers).then(res => {
      const qdata = res.data;
      setUsersData(qdata);
    });
  };

  const getUsersGlobalSettings = async () => {
    await API.request(API_ENDPOINTS.getAllUsersGlobalSettings).then(res => {
      const qdata = res.data;
      setUsersSettings(qdata);
    });
  };

  const getDiscordServersForAdminEdit = async () => {
    await API.request(API_ENDPOINTS.getDiscordServersForAdminEdit).then(res => {
      const qdata = res.data;
      setDiscordServersAdminEdit(qdata);
    });
  };

  //The following is for setting the default server for roles
  const getMyRolesRead = async () => {
    if (selectedServerData) {
      await API.request(API_ENDPOINTS.getMyServerRolesRead, undefined, selectedServerData['item_id'])
        .then(res => {
          const qdata = res.data;
          setMyRolesReadData(qdata);
          //If there are no roles myRolesReadData will be length 0

          const selectedServer = discordServersAdminEdit.find(server => server.id === selectedServerData.item_id);
          if (selectedServer) {
            setSelectedServerGuildID(selectedServer.guild_id);
          }
          setSelectedRoleData({
            index: qdata.findIndex(item =>
              item.id === (selectedRoleData.id || 1)),
            id: selectedRoleData.id || 1
          });
        });
    }

  };

  useEffect(() => {
    getMyRolesRead();
  }, [selectedServerData]);

  useEffect(() => {
    // console.log("selectedRoleData ", selectedRoleData);
    if (myRolesReadData && myRolesReadData.length > 0) {
      const selectRole = myRolesReadData.find(roles => roles.id === selectedRoleData.item_id);
      if (selectRole) {
        setSelectedRoleName(selectRole.role);
      }
    }
  }, [selectedRoleData]);

  useEffect(() => {
    getUsers();
    getUsersGlobalSettings();
    getDiscordServersForAdminEdit();
  }, []);

  const handleSelectChange = (event) => {
    setPeriod(event.target.value);
  };


  return (
    <HStack>
      <Box maxW="lg">
        <NiceTabs isFitted variant='enclosed'>
          <TabContent label="User Menu">
            <HeavyTabs layout={[
              // EDIT USERS
              {
                label: 'Edit Users',
                renderOn: usersData,
                content: (
                  <DynamicFormWithSelect
                    select_display_property='username'
                    select_text='Select User'
                    select_data={usersData}
                    schema={schemasData?.UserResponseModel}
                    endpoint={API_ENDPOINTS.updateUser}
                    success_msg='User updated!'
                    updateCallback={getUsers}
                  />)
              },
              // INVITE USER
              {
                label: 'Invite User',
                renderOn: usersData,
                content: (
                  <DynamicForm
                    schema={schemasData?.InvitationModel}
                    endpoint={API_ENDPOINTS.inviteUser}
                    success_msg='User has been sent an invitation link to their email.'
                    updateCallback={getUsers}
                  />)
              },
              // UPDATE USER GLOBAL SETTINGS
              {
                label: 'Edit User Subscription',
                renderOn: usersSettings,
                content: (
                  <DynamicFormWithSelect
                    select_display_property='username'
                    select_text='Select User'
                    select_data={usersSettings}
                    exclude_property={['username']}
                    schema={schemasData?.UserGlobalSettingsReadModel}
                    endpoint={API_ENDPOINTS.setAllUsersGlobalSettings}
                    success_msg='User subscription updated!'
                    usePathID={true}
                    updateCallback={getUsersGlobalSettings}
                  />)
              },
              // UPDATE NOREPLY USER
              {
                label: 'Authenticate Google Account',
                renderOn: true,
                content: (
                  <Box>
                    <Button onClick={() => {
                      window.open(API_ENDPOINTS.authenticateGoogleAccount.url + `?token=${localStorage.getItem('men_QT')}`, '_blank');
                    }}>
                      Authenticate Google Noreply Account
                    </Button>

                    <br /><br />
                    <DynamicForm
                      schema={schemasData?.AppConfigModel}
                      endpoint={API_ENDPOINTS.updateEmailConfig}
                      success_msg='Gmail settings updated.'
                    />
                  </Box>
                )
              },

            ]} />
          </TabContent>

          <TabContent label='Discord Menu'>
            <HeavyTabs layout={[
              // VIEW ALL DISCORD SERVERS
              {
                label: 'View Discord Servers',
                renderOn: discordServersAdminEdit,
                content: (
                  <DynamicFormWithSelect
                    select_display_property='nickname'
                    select_text='Select Discord Server'
                    select_data={discordServersAdminEdit}
                    schema={schemasData?.DiscordServerAdminReadModel}
                  />)
              },
              // EDIT ALL DISCORD SERVERS
              {
                label: 'Edit Discord Servers',
                renderOn: discordServersAdminEdit,
                content: (
                  <DynamicFormWithSelect
                    select_display_property='nickname'
                    select_text='Select Discord Server'
                    select_data={discordServersAdminEdit}
                    schema={schemasData?.DiscordServerAdminEditModel}
                    endpoint={API_ENDPOINTS.updateDiscordServersForAdminEdit}
                    success_msg='Discord server requests updated!'
                    updateCallback={getDiscordServersForAdminEdit}
                  />)
              },
              // UPDATE DISCORD APPLICATION ID
              {
                label: 'Set Discord App Creds',
                renderOn: true,
                content: (
                  <Box>
                    <Heading as='h6' size='md'>
                      Update your Discord Application Credentials
                    </Heading>
                    <DynamicForm
                      schema={schemasData?.DiscordAppCredsModel}
                      endpoint={API_ENDPOINTS.updatediscordAppCreds}
                      success_msg='App Creds updated.'
                    />
                  </Box>
                )
              },
            ]} />
          </TabContent>

          <TabContent label='Bot Commands Menu'>
            <HeavyTabs layout={[
              // MESSAGEQUEUE SERVER REQUESTS
              {
                label: 'Request Server Information',
                renderOn: discordServersAdminEdit,
                content: (
                  <Box>
                    <RequestForm
                      title='Download Server User List'
                      message={{
                        command: "get_user_list",
                        guild_id: selectedServerGuildID
                      }}
                    >
                      <>
                        <DynamicFormWithSelect
                          select_display_property='nickname'
                          select_text='Select Discord Server'
                          select_data={discordServersAdminEdit}
                          selectedIndexState={[selectedServerData, setSelectedServerData]}
                        />
                      </>
                    </RequestForm>
                    <br />
                    <RequestForm
                      title='Download Server User List for Specific Roles'
                      disabled={!(myRolesReadData && myRolesReadData.length > 0)}
                      message={{
                        command: "get_user_list",
                        guild_id: selectedServerGuildID,
                        role: selectedRoleName
                      }}
                    >
                      <>
                        <DynamicFormWithSelect
                          select_display_property='nickname'
                          select_text='Select Discord Server'
                          select_data={discordServersAdminEdit}
                          selectedIndexState={[selectedServerData, setSelectedServerData]}
                        />
                        <DynamicFormWithSelect
                          select_display_property='role'
                          select_text='Select Role'
                          select_data={myRolesReadData}
                          renderOn={(myRolesReadData && myRolesReadData.length > 0)}
                          selectedIndexState={[selectedRoleData, setSelectedRoleData]}
                        />
                      </>
                    </RequestForm>
                  </Box>
                )
              },
              // CLEAR CACHE
              {
                label: 'Clear Cache',
                renderOn: true,
                content: (
                  <Box>
                    <RequestForm
                      title='Select Cache Options'
                      message={{
                        command: "clear_cache",
                        period: period
                      }}
                    >
                      <>
                        <Select value={period} onChange={handleSelectChange}>
                          <option value='today'>Clear today's cache</option>
                          <option value='week'>Clear weekly cache</option>
                          <option value='all'>Clear all cache</option>
                        </Select>
                        <br />
                      </>
                    </RequestForm>
                  </Box>
                )
              }
            ]} />
          </TabContent>
        </NiceTabs>

      </Box>
    </HStack>
  )
};

export default AdminPage;