import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../services/apiConfig';
import API from '../../services/apiRequest';
import { useToast } from '@chakra-ui/react';

let listeners = [];
let loggedInUsersListeners = [];
let ATlisteners = [];

const notifyListeners = () => {
  listeners.forEach((listener) => listener(authCache.getAuthStatus()));
};

const notifyUsernameListeners = (username) => {
  loggedInUsersListeners.forEach((listener) => listener(LoggedInUser.getUsername()));
};

const notifyATlisteners = (at) => {
  ATlisteners.forEach((listener) => listener(LoggedInUser.getAccountType()));
};

const storeData = (key, data) => {
  //const encodedData = window.btoa(data);
  sessionStorage.setItem(key, data);
};

const retrieveData = (key) => {
  const encodedData = sessionStorage.getItem(key);
  if (!encodedData) return null;
  const decodedData = window.atob(encodedData);
};

const decodeData = (data) => {
  return window.atob(data);
}

const authCache = {
  setAuthStatus: function(isAuthenticated) {
    sessionStorage.setItem('isAuthenticated', isAuthenticated ? 'true' : 'false');
    notifyListeners(); // Notify all listeners about the change
  },
  getAuthStatus: function() {
    const status = sessionStorage.getItem('isAuthenticated');
    return status ? status === 'true' : null;
  },

  subscribe: function(listener) {
    listeners.push(listener);
    return () => { // Return an unsubscribe function
      listeners = listeners.filter(l => l !== listener);
    };
  }
};


const login = async (values, navigate) => {
  return await API.request(API_ENDPOINTS.login, values).then(res => {
    const status = res.data?.success ?? true;

    // Try to extract the jwt token:
    if (status) {
      const token = res.data.access_token;
      localStorage.setItem('men_QT', token);
    }
    authCache.setAuthStatus(true);
    LoggedInUser.setUsername(values['username']); // Assuming the username is in the response
    LoggedInUser.setAccountType(res.data['_acx']);

  }).catch(err => {
    const err_msg = err.response.data?.detail.detail ?? err.response.data?.detail ?? err.response.data?.detail.success ?? "An error has occured."; 
    return err_msg;
  });

}

const LoggedInUser = {
  setUsername: function(username) {
    sessionStorage.setItem('_username', username);
    notifyUsernameListeners();
  },
  getUsername: function() {
    return sessionStorage.getItem('_username');
  },
  getAccountType: function() {
    const type = sessionStorage.getItem('_acx') || null;
    return decodeData(type);
  },
  setAccountType: function(val) {
    storeData('_acx', val);
    notifyATlisteners(val);
  },
  subscribe: function(listener) {
    loggedInUsersListeners.push(listener);
    return () => { // Return an unsubscribe function
      loggedInUsersListeners = loggedInUsersListeners.filter(l => l !== listener);
    };
  },
  subscribeAT: function(listener) {
    ATlisteners.push(listener);
    return () => { // Return an unsubscribe function
      ATlisteners = ATlisteners.filter(l => l !== listener);
    };
  }
};

const AuthRoute = ({ children, publicOnly = false, adminOnly = false }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(authCache.getAuthStatus());
  const [accountType, setAccountType] = useState(LoggedInUser.getAccountType());
  useEffect(() => {
    const unsubscribeAuth = authCache.subscribe((newAuthStatus) => {
      setIsAuthenticated(newAuthStatus);
    });

    const unsubscribeAccountType = LoggedInUser.subscribeAT((newAccountType) => {
      setAccountType(newAccountType);
    });
    return () => {
      unsubscribeAuth();
      unsubscribeAccountType();
    };
  }, []);

  if (isAuthenticated && publicOnly) {
    return <Navigate to="/" replace />;
  }

  if (!isAuthenticated && !publicOnly) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && accountType !== 'admin') {
    return <Navigate to="/" replace />;
  }

  return children;
};

export { AuthRoute, authCache as AuthCache, LoggedInUser, login}