import React, { useEffect, useState } from 'react';
import API from '../../services/apiRequest';
import { Box, Text, Switch, FormControl, FormLabel, SimpleGrid, Select } from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'

const DynamicTable = ({ data, title, ...props }) => {
    const [tableData, setTableData] = useState({});
    const [headers, setHeaders] = useState([]);
    useEffect(() => {
        const firstItemKey = Object.keys(data)[0];
        if (data && firstItemKey && data[firstItemKey].length > 0) {
            setHeaders(Object.keys(data[firstItemKey][0]));
        }
        setTableData(data);
    }, [data]);

    return (
            <>
            { tableData && Object.entries(tableData).map(([_key, _values]) => (
            <TableContainer key={_key} maxHeight="500px" overflowY="auto">
                <Table variant='striped' colorScheme='teal'>
                    <TableCaption>{title}</TableCaption>
                    <Thead>
                        <Tr>
                            {headers.map((item) => (
                                <Th key={item}>{item}</Th> // Ensure you have a 'key' prop for each item for React to manage DOM updates efficiently.
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            Object.entries(_values).map(([key, values]) => (
                                <Tr key={key}>
                                    {Object.entries(values).map(([entry, value]) => (
                                        <Td key={entry}>{value}</Td> // Mapping each value in the array to a cell, using the array index as a key.
                                    ))}
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            )) }
            </>
        );
};

export { DynamicTable };

