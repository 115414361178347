import React, { useEffect, useState } from 'react';
import { DynamicForm, DynamicFormWithSelect } from '../../components/DynamicForm';
import { Box, Text, HStack, useToast, Button, Center } from "@chakra-ui/react";
import API from '../../services/apiRequest';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { schemas } from '../../services/schemas';
import HeavyTabs, { NiceTabs, TabContent } from '../../components/HeavyTabs';
import { SwitchListWithSelection } from '../../components/SwitchList';
import DynamicSelect from '../../components/DynamicSelect';

const MyServersContainer = () => {
    const toast = useToast();
    const [schemasData, setSchemasData] = useState(null);
    const [myServersReadData, setMyServersReadData] = useState(null);
    const [myServersEditData, setMyServersEditData] = useState(null);
    const [selectedReadIndex, setSelectedReadIndex] = useState({ index: null, server_id: null });
    const [selectedEditIndex, setSelectedEditIndex] = useState({ index: null, server_id: null });
    const [selectedServerRoleData, setSelectedServerRoleData] = useState(null);
    const [myRolesReadData, setMyRolesReadData] = useState(null);
    const [selectedRoleReadIndex, setSelectedRoleReadIndex] = useState({ index: null, server_id: null });
    const [selectedServerForFunction, setSelectedServerForFunction] = useState(null);

    const defaultToastOptions = {
        duration: 5000,
        isClosable: true,
        position: "top"
    }
    const getMyServersRead = async () => {
        await API.request(API_ENDPOINTS.getMyDiscordServersRead).then(res => {
            const qdata = res.data;
            setMyServersReadData(qdata);
            setSelectedReadIndex({ index: qdata.findIndex(item => item.id === (selectedReadIndex.server_id || 1)), server_id: selectedReadIndex.server_id || 1 });
        });
    };

    const getMyServersEdit = async () => {
        await API.request(API_ENDPOINTS.getMyDiscordServersEdit).then(res => {
            const qdata = res.data;
            setMyServersEditData(qdata);
            setSelectedEditIndex({ index: qdata.findIndex(item => item.id === (selectedEditIndex.server_id || 1)), server_id: selectedEditIndex.server_id || 1 });
        });
    };

    //The following is for setting the default server for roles
    const getMyRolesRead = async () => {
        if (selectedServerRoleData) {
            await API.request(API_ENDPOINTS.getMyServerRolesRead, undefined, selectedServerRoleData['item_id'])
                .then(res => {
                    const qdata = res.data;
                    setMyRolesReadData(qdata);
                    setSelectedRoleReadIndex({ index: qdata.findIndex(item => item.id === (selectedRoleReadIndex.id || 1)), id: selectedRoleReadIndex.id || 1 });
                });
        }

    };

    const getSchemas = async () => {
        const sdata = await schemas.getSchemas();
        setSchemasData(sdata);
    };

    useEffect(() => {
        getSchemas();
        getMyServersRead();
        getMyServersEdit();
    }, []);

    useEffect(() => {
        getMyRolesRead();
    }, [selectedServerRoleData]);

    const updateMyServersCallback = () => {
        getMyServersRead();
        getMyServersEdit();
    }

    const updateMyServersCallbackForRoles = () => {
        getMyRolesRead();
    }

    const handleEditMenuChange = (value) => {
        setSelectedEditIndex({ index: value, server_id: myServersEditData[value].id });
    }

    const handleSubcommandToggle = (event, update_model) => {
        const id = event.target.id;
        const checked = event.target.checked;
        API.request(API_ENDPOINTS.toggleServerCommandUpdate, {
            ...update_model
        })
            .then(async res => {
                await toast({
                    title: "Updated!",
                    description: `Your command is now ${checked ? 'active' : 'disabled'}`,
                    duration: 5000,
                    ...defaultToastOptions
                });
            }).catch(async err => {
                await toast({
                    title: "Error",
                    description: "Issue updating the command.",
                    status: "error",
                    ...defaultToastOptions
                });
            });
    }

    const addBotToServer = () => {
        const server = myServersReadData[selectedServerForFunction.index];
        API.request(API_ENDPOINTS.addDiscordToServer, null, server['guild_id'])
            .then(async res => {
                window.open(res.data.url, "_blank");
            }).catch(async err => {
                console.log(err);
                toast({
                    title: "Error",
                    description: err.response.data.detail,
                    status: "error",
                    ...defaultToastOptions
                });
            });
    }


    return (
        <HStack>

            <Box>
                <NiceTabs isFitted variant='enclosed'>
                    <TabContent label="Discord Server Menu">
                        {/**
                         * NiceTabs and HeavyTabs are the EXACT SAME THING. If you want to do it my dictionary, 
                         * or by children nodes, use the appropriate one. I did it this way because I am nesting
                         * and readability and I'm lazy to fix
                         */}
                        <HeavyTabs layout={
                            [
                                // CREATE NEW DISCORD SERVER
                                {
                                    label: 'Create New Discord Server',
                                    renderOn: schemasData,
                                    content: (
                                        <DynamicForm
                                            schema={schemasData?.DiscordServerCreateModel}
                                            endpoint={API_ENDPOINTS.createDiscordServer}
                                            success_msg='Discord Server created!'
                                            updateCallback={updateMyServersCallback}
                                        />)
                                },

                                // VIEW DISCORD SERVERS
                                {
                                    label: 'View My Servers',
                                    renderOn: myServersReadData,
                                    content: (
                                        <DynamicFormWithSelect
                                            select_data={myServersReadData}
                                            select_display_property='nickname'
                                            select_text='Select Discord Server'
                                            schema={schemasData?.DiscordServerReadModel}
                                        />)
                                },

                                // EDIT SERVER COMMAND
                                {
                                    label: 'Edit My Servers',
                                    renderOn: myServersEditData,
                                    content: (
                                        <DynamicFormWithSelect
                                            select_data={myServersEditData}
                                            select_display_property='nickname'
                                            select_text='Select Discord Server'
                                            schema={schemasData?.DiscordServerEditModel}
                                            endpoint={API_ENDPOINTS.updateDiscordServer}
                                            success_msg='Discord Server updated!'
                                            removable={API_ENDPOINTS.deleteDiscordServer}
                                            header_text='Delete this server?'
                                            body_text='This action will delete all associated roles and customizations permanently!'
                                            submit_text='Delete'
                                            updateCallback={updateMyServersCallback}
                                        />)
                                },

                                // HELP
                                {
                                    label: 'Help/Docs for Commands',
                                    renderOn: true,
                                    content: (
                                        <Box>
                                            <Text>
                                                Documentation...
                                            </Text>
                                        </Box>)
                                }
                            ]
                        } />
                    </TabContent>

                    <TabContent label="Roles Menu">
                        <HeavyTabs layout={
                            [
                                // CREATE NEW DISCORD ROLE
                                {
                                    label: 'Create New Discord Role',
                                    renderOn: myServersEditData,
                                    content: (
                                        <DynamicFormWithSelect
                                            select_data={myServersEditData}
                                            select_display_property='nickname'
                                            select_text='Select Discord Server'
                                            schema={schemasData?.DiscordRoleCreateModel}
                                            exclude_property={['nickname']}
                                            endpoint={API_ENDPOINTS.createDiscordRole}
                                            success_msg='Discord Role created!'
                                            updateCallback={updateMyServersCallback}
                                        />)
                                },

                                // VIEW DISCORD ROLES
                                {
                                    label: 'View My Server Roles',
                                    renderOn: myServersReadData,
                                    content: (
                                        <>
                                            <DynamicFormWithSelect
                                                select_data={myServersReadData}
                                                select_display_property='nickname'
                                                select_text='Select Discord Server'
                                                selectedIndexState={[selectedServerRoleData, setSelectedServerRoleData]}
                                            />
                                            <DynamicFormWithSelect
                                                select_data={myRolesReadData}
                                                select_display_property='role'
                                                select_text='Select Role'
                                                renderOn={(myRolesReadData && myRolesReadData.length > 0)}
                                                schema={schemasData?.DiscordRoleReadModel}
                                                exclude_property={['discord_guild']}
                                            />
                                        </>
                                    )
                                },

                                // EDIT DISCORD ROLES
                                {
                                    label: 'Edit My Server Roles',
                                    renderOn: myServersEditData,
                                    content: (
                                        <>
                                            <DynamicFormWithSelect
                                                select_data={myServersEditData}
                                                select_display_property='nickname'
                                                select_text='Select Discord Server'
                                                selectedIndexState={[selectedServerRoleData, setSelectedServerRoleData]}
                                            />
                                            <DynamicFormWithSelect
                                                select_data={myRolesReadData}
                                                select_display_property='role'
                                                select_text='Select Role'
                                                renderOn={(myRolesReadData && myRolesReadData.length > 0)}
                                                schema={schemasData?.DiscordRoleEditModel}
                                                endpoint={API_ENDPOINTS.updateDiscordRole}
                                                removable={API_ENDPOINTS.deleteDiscordRole}
                                                header_text='Delete this role?'
                                                submit_text='Delete'
                                                success_msg='Server Role updated!'
                                                updateCallback={updateMyServersCallbackForRoles}
                                            />
                                        </>
                                    )
                                },

                                // HELP
                                {
                                    label: 'Help/Docs for Commands',
                                    renderOn: true,
                                    content: (
                                        <Box>
                                            <Text>
                                                Documentation...
                                            </Text>
                                        </Box>)
                                }
                            ]
                        } />
                    </TabContent>
                    <TabContent label="Discord Functions Menu">

                        <HeavyTabs layout={
                            [
                                // ADD BOT TO SERVER
                                {
                                    label: 'Add Bot to Server',
                                    renderOn: true,
                                    content: (
                                        <Box>

                                            <DynamicSelect
                                                select_data={myServersReadData}
                                                selectedIndexState={[selectedServerForFunction, setSelectedServerForFunction]}
                                                select_display_property='nickname'
                                                select_text="Select Discord Server"

                                            />
                                            <br />
                                            <Center>

                                                <Button colorScheme="green" onClick={addBotToServer}>
                                                    Add Discord to Server
                                                </Button>
                                            </Center>
                                        </Box>
                                    )
                                },

                            ]} />
                    </TabContent>
                    <TabContent label="Customize Commands Menu">

                        <HeavyTabs layout={
                            [
                                // CREATE NEW CUSTOM COMMAND
                                {
                                    label: 'Customize Command',
                                    renderOn: true,
                                    content: (
                                        <SwitchListWithSelection callback={handleSubcommandToggle}
                                            list_endpoint={API_ENDPOINTS.getServerCommandsForSelection} />)
                                },

                                // HELP
                                {
                                    label: 'Help/Docs for Commands',
                                    renderOn: true,
                                    content: (
                                        <Box>
                                            <Text>
                                                Documentation...
                                            </Text>
                                        </Box>)
                                }
                            ]
                        } />
                    </TabContent>

                </NiceTabs>
            </Box>
        </HStack>
    );
};

export default MyServersContainer;
