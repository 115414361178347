import React, { useState, useEffect } from 'react';
import {
  Box, Text, Select, useToast,
  systemProps
} from '@chakra-ui/react';
import { DynamicAlertDialog } from '../DynamicAlertDialog'
import { DynamicForm } from '.';

/**
 * 
 * @param {function} select_display_format the callback. passes in variable `item`
 * @returns 
 */
const WithSelect = ({ select_data, select_display_property = 'name',
  select_display_format, exclude_property,
  select_text = 'Select Item', schema, endpoint, usePathID, renderOn = true,
  selectedIndexState, removable = false,
  updateCallback = false, success_msg, ...props }) => {

  //const toast = useToast();
  const [itemData, setItemData] = useState(null);
  const [localSelectedItemIndex, setLocalSelectedItemIndex] = useState({});

  const [selectedItemIndex, setSelectedItemIndex] = selectedIndexState || [localSelectedItemIndex, setLocalSelectedItemIndex];
  const [canLoad, setCanLoad] = useState(false);

  useEffect(() => {

    setItemData(select_data);
    setCanLoad(select_data && select_data.length > 0);

    if (select_data && select_data.length > 0) {
      if (selectedItemIndex && selectedItemIndex.item_id) {
        // Find index based on provided item_id
        const foundIndex = select_data.findIndex(item => item.id === selectedItemIndex.item_id);
        // Item was deleted or removed, replace with first find
        if (foundIndex === -1) {
          foundIndex = 0;
        }
        setSelectedItemIndex({ index: foundIndex >= 0 ? foundIndex : 0, item_id: selectedItemIndex.item_id });
      } else {
        // Default to first item if no item_id is provided
        setSelectedItemIndex({ index: 0, item_id: select_data[0].id });
      }
    }
  }, [select_data]);

  const handleEditMenuChange = (value) => {
    setSelectedItemIndex({ index: value, item_id: itemData[value].id });
    // console.log("SelectedItemIndex: ", selectedItemIndex.item_id);
  }






  if (renderOn) {
    return (
      <Box>
        <Text as='b'>
          {select_text}
        </Text>
        <br />
        {(itemData && selectedItemIndex) &&
          <Select value={selectedItemIndex.index} onChange={(e) => handleEditMenuChange(e.target.value)}>
            {itemData && itemData.map((item, index) => (
              <option key={index} value={index.toString()}>
                {select_display_format ? select_display_format(item) : item[select_display_property]}
              </option>
            ))}
          </Select>
        }
        {(canLoad && selectedItemIndex && itemData) && (
          <DynamicForm
            schema={schema}
            endpoint={endpoint}
            exclude_property={exclude_property}
            success_msg={success_msg}
            initialValues={itemData[selectedItemIndex['index']]}
            usePathID={usePathID}
            renderOn={renderOn}
            updateCallback={updateCallback}
          />
        )}
        {(canLoad && removable) && (
          <DynamicAlertDialog
            endpoint={removable}
            button_text={props.button_text}
            header_text={props.header_text}
            body_text={props.body_text}
            submit_text={props.submit_text}
            success_msg={"Delete Successful"}
            obj_id={selectedItemIndex.item_id}
            updateCallback={updateCallback}
          />
        )}
      </Box>
    )
  }
}

export default WithSelect;