import React, { useState, useEffect } from 'react';
import {
  Box, FormControl, Text, FormLabel, Input, Checkbox, NumberInput,
  NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
  Button, VStack, IconButton, Stack, HStack, useToast, SkipNavContent
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

import API from '../../services/apiRequest';
import WithSelect from './withselect';
import { API_BASE_URL } from '../../services/apiConfig';
import { Select } from 'chakra-react-select';
import DynamicSelectBox from './dynamicSelect';
import InternalDateBox from './dateBox';
import { object } from 'prop-types';

const resolveSchemaType = (property) => {
  if (property.type) {
    return property.type;
  } else if (property.anyOf) {
    const typeObject = property.anyOf.find(type => ['string', 'boolean', 'integer', 'array'].includes(type.type));
    return typeObject ? typeObject.type : 'string';
  }
  return 'string';
};

/**
 * Check if object is an actual object data type
 * @param {*} value 
 * @returns 
 */
const isObjectLike = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}


const DynamicForm = ({ schema, endpoint, initialValues, exclude_property = [], hide_property = [],
                        success_msg = "Form submitted successfully!", usePathID = false, renderOn = true,
                        updateCallback = false, ...props }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({});
  const [formDataOptions, setFormDataOptions] = useState({});
  const [checkboxValues, setCheckboxValues] = useState({});
  const isFieldRequired = (fieldName) => {
    // Check if the field is included in the 'required' array of the schema
    return schema.required?.includes(fieldName);
  };

  const initializeFormData = async () => {
    if (schema) {
      const initialData = {};
      const initialDataOptions = {};
      const dynamicDataPromises = [];

      Object.entries(schema.properties).forEach(([key, property]) => {
        const type = resolveSchemaType(property);
        let iniValue = initialValues && initialValues[key] !== undefined
          ? initialValues[key]
          : type === 'array' ? [] : '';
        
        if (type === 'string' && property?.format === 'date') {
          iniValue = new Date();
        }

        if (iniValue === '' && property.default !== undefined) {
          iniValue = property.default;
        }

        const data = property.enum || property.anyOf?.find((p) => p.type === type)?.enum;
        if (type === 'string' && data && iniValue === undefined) {
          iniValue = data[0];
        }

        initialData[key] = iniValue;

        // For dynamic lists, fetch the data and assign it asynchronously
        if (property.dynamic_list !== undefined) {
          const link = property.dynamic_list.url;
          if (link.indexOf("{id}") >= 0) {
            link.replace("{id}", initialValues.id); // not working logic yet
          }
          const fetchPromise = API.request({ method: 'get', url: `${API_BASE_URL}${link}` })
            .then(res => {
              const list_field = property.dynamic_list?.field
              let sub_field_type = property.dynamic_list?.sub_field_type;
              if (!list_field) {
                initialDataOptions[key] = res.data;
              }
              // if set, the `field` passed is an object that needs further mapping
              else if (sub_field_type) {
                initialDataOptions[key] = res.data[list_field];
                //initialDataOptions[key] = Object.entries(res.data[list_field]).map(([k, v]) => {return {k,v}})//sub_field_type == 'key' ? k : v);
                //initialDataOptions[key] = Object.entries(initialDataOptions[key]).map(([k, v]) => (console.log(k,v)))
              } else {
                initialDataOptions[key] = Object.values(res.data).map(item => item[list_field]);
              }
            });
          dynamicDataPromises.push(fetchPromise);
        }
      });

      // Wait for all dynamic data to be fetched and assigned
      await Promise.all(dynamicDataPromises);
      setFormData(initialData);
      setFormDataOptions(initialDataOptions);
    }
  };


  useEffect(() => {  
    initializeFormData();
  }, [initialValues, schema]);
  
  const handleDateChange = (key, date) => {
    setFormData(prev => ({ ...prev, [key]: date }));
  }

  const handleChange = (e, key, type, index = null) => {
    if (type === 'integer') {
      setFormData(prev => ({ ...prev, [key]: e}));
        return;
    }
    const { value, checked } = e.target;
    if (type === 'array') {
      setFormData(prev => {
        const list = [...prev[key]];
        if (index != null) {
          list[index] = value;
        }
        return { ...prev, [key]: list };
      });
    } else {
      const newValue = type === 'boolean' ? checked : value;
      setFormData(prev => ({ ...prev, [key]: newValue }));
    }
  };

  const handleSelectItemChange = (e, key) => {
    setFormData(prev => {
      return {...prev, [key]: e};
    });
  }

  const handleAddItem = (key) => {
    setFormData(prev => {
      const list = [...prev[key], ''];
      return { ...prev, [key]: list };
    });
  };

  const handleRemoveItem = (key, index) => {
    setFormData(prev => {
      const list = [...prev[key]];
      list.splice(index, 1);
      return { ...prev, [key]: list.length ? list : [''] }; // Ensure at least one empty string remains
    });
  };

  function capitalizeFirstLetter(string) {
    if (!string) return string; // return the original string if it's falsy
    return string.charAt(0).toUpperCase() + string.slice(1);
  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let isValid = true;
    if (schema.required) {
      for (const field of schema.required) {
        if (!formData[field] || (Array.isArray(formData[field]) && formData[field].some(item => !item))) {
          isValid = false;
          toast({
            title: "Error",
            description: `${field} is required`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          break; // Stop checking if any required field is invalid
        }
      }
    }
    if (!isValid) return; // Stop the submission if validation fails

      // Transform the data so that chakra-react-select objects can work with the API
    const transformedData = { ...formData };
    Object.entries(schema.properties).forEach(([key, property]) => {
      const type = resolveSchemaType(property);
      if (type === 'array' && property.hasOwnProperty('dynamic_list')) {
        if (!(endpoint && endpoint.method === 'put' && initialValues && initialValues.commands === formData.commands)) {
          transformedData[key] = transformedData[key].map(item => item.value);
        }
      };
      if (type === 'string' && typeof(formData[key]) === 'object') {
        transformedData[key] = formData[key]['value'];
      }
    });

    let newEndpoint = {...endpoint};
    // Modify endpoint to match the ID if usePathID is `true`
    if (usePathID) {
      newEndpoint = { ...endpoint };
      const id = initialValues?.id;
      if (!id) {
        toast({
          title: "Error",
          description: "Cannot submit an empty form!",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top", // or "bottom", depending on your preference
        });
        return;
      }
      newEndpoint.url += `/${id}`;
    }
    await API.request(newEndpoint, transformedData).then(res => {
      const status = res.data?.success ?? true;
      if (!status) {
        toast({
          title: "Error",
          description: "An error has occured.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top", // or "bottom", depending on your preference
        });
      }
      toast({
          title: "Success",
          description: success_msg,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top", // or "bottom", depending on your preference
        });
        // reload data
        initializeFormData();
        if (updateCallback) {
          updateCallback();
        }
    }).catch(err => {
      
      const err_msg = err.response.data?.detail.detail ?? err.response.data?.detail ?? err.data?.detail ?? err.response.data?.detail.success ?? "An error has occured."; 
      console.log("Error message: ", err)
      toast({
        title: "Error",
        description: err_msg,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top", // or "bottom", depending on your preference
      });
    });
  };

  if (renderOn) {
    return (
      <Box align="center" borderRadius="20px" bg="#1B2038" p={4} maxW="md" {...props}>
        <Stack as="form" direction={'column'} spacing={2} onSubmit={handleSubmit}>
        {(schema && formData) && Object.entries(schema.properties)
        .filter(([key, _]) => !exclude_property.includes(key))
        .map(([key, property]) => {
          
          let type = resolveSchemaType(property);
          let fieldValue = formData[key];
          let fieldValueOptions = formDataOptions[key];
          const format = property?.format;
          const pattern = property?.input_type;
          const defaultValue = property?.default;
          const required = isFieldRequired(key);
          const hidden = hide_property?.includes(key);
          let dynamic_list = null;
          /// Don't add ID to the field. These are identifiers for the object/model
          if (key === 'id' || hidden) {
            return
          }
          if (type === 'object') {
            type = 'string';
          }
          if (type === 'array' && property.hasOwnProperty('dynamic_list')) {
            if (fieldValue && !isObjectLike(fieldValue?.[0])) {
              const transformedData = fieldValue.map(val => ({
                label: val,
                value: val
              }));
              fieldValue = transformedData;
            }
          } 
          // Prepare the JSX elements for array fields
          const arrayFieldElements = Array.isArray(fieldValue) ? fieldValue.map((item, index) => (
            <HStack key={`${key}_${index}`} spacing={2} align="center" width="full">
              <Input
              flex="1"
                value={item}
                hidden={hidden}
                onChange={(e) => handleChange(e, key, 'array', index)}
                placeholder={`${property.title || key} #${index + 1}`}
              />
              <IconButton
                aria-label="Remove item"
                icon={<CloseIcon />}
                onClick={() => handleRemoveItem(key, index)}
              />
            </HStack>
          )) : null;

          // For properties that have a literal type, fixed enum list
          if (property.enum || (property.anyOf?.find((p) => p.type === type)?.enum)) {
            if (typeof(fieldValue) === 'string' && fieldValue.length > 0) {
              fieldValue = {
                'label': fieldValue,
                'value': fieldValue
              }
            }
          }
          const arraySelectElements = (
            <DynamicSelectBox
              fieldValue={fieldValue}
              fieldValueOptions={formDataOptions[key]}
              property={property}
              propertyKey={key}
              handleSelectItemChange={handleSelectItemChange}
              readOnly={!endpoint}
            />);

          return (
            <FormControl key={key} mb={4} isRequired={required}>
              <FormLabel hidden={hidden}>{property.title || key}{required && ""}</FormLabel>
              <Box textAlign='left' direction='ltr'>
                <Text as='i' fontSize='sm'>{property?.description}</Text>
              </Box>
              {type !== 'array' ? (
                // Render non-array fields
                type === 'string' && format === 'date' && fieldValue ? (
                  <InternalDateBox property={fieldValue} callback={handleDateChange}/>
                  ) : type === 'string' ? (
                  <Box>                  
                    {
                    //String that has a dynamic list
                    fieldValueOptions ? (<Box>
                        {arraySelectElements}
                      </Box>)

                    : // FOR STRING VALUES WITH LITERAL DICTIONARIES
                    (property.enum || (property.anyOf?.find((p) => p.type === type)?.enum)) ? (
                      <Select name={key} defaultValue={defaultValue} 
                      value={(fieldValue !== undefined && Object.keys(fieldValue).length > 0) && fieldValue}
                      options={[
                        {
                          label: '',
                          options: (property.enum || (property.anyOf?.find((p) => p.type === type)?.enum)).map((item, index) => ({
                            label: item,
                            value: item
                          }))
                        }]}
                        onChange={(e) => handleSelectItemChange(e, key)}/>

                    ) : // PLAIN STRING
                    (
                      <Input
                      name={key}
                      value={fieldValue || ''}
                      onChange={(e) => handleChange(e, key, type)}
                      type={pattern ?? 'text'}
                      placeholder={property.title || key}
                      readOnly={!endpoint}
                      hidden={hidden}
                      />
                    )}
                  </Box>
                ) : type === 'boolean' ? (
                  <Checkbox
                    name={key}
                    isChecked={Boolean(fieldValue)}
                    onChange={(e) => handleChange(e, key, type)}
                    readOnly={!endpoint}
                    hidden={hidden}
                  />
                ) : type === 'integer' ? (
                  <NumberInput name={key}
                    value={fieldValue || 0}
                    min={property.minimum ?? (property.anyOf?.find((p) => p.type === type)?.minimum)} 
                    max={property.maximum ?? (property.anyOf?.find((p) => p.type === type)?.maximum)}
                    onChange={(e) => handleChange(e, key, type)}
                    readOnly={!endpoint}
                    hidden={hidden}
                    >
                        
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  ) : null
              ) : type === 'array' ? (
                property.hasOwnProperty('dynamic_list') && fieldValueOptions !== undefined ? (
                <Box>
                  {arraySelectElements}
                </Box>
                ) : (
                // Render array fields using the prepared JSX elements
                <VStack spacing={2}>
                  {arrayFieldElements}
                  <Button size="sm" readOnly={!endpoint} onClick={() => handleAddItem(key)}>Add Item</Button>
                </VStack>
              )): null}
            </FormControl>
          );
        })}

          {endpoint && <Button colorScheme="blue" type="submit">Submit</Button>}
        </Stack>
      </Box>
    );
  };
}

export { DynamicForm, WithSelect as DynamicFormWithSelect };





