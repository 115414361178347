import axios from "axios";
import { useToast } from "@chakra-ui/react";


const API = {
    request: function(api, data = null, path = null) {

        const token = localStorage.getItem('men_QT');

        // Prepare base headers
        const headers = {
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` })
        };

        return axios.request({
            url: path ? `${api.url}/${path}` : api.url,
            method: api.method,
            data: data,
            headers: headers,
            withCredentials: true,
        }).catch(error => {
            console.error(error);
            throw error;
        });
    }
};

export default API
