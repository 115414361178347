import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthCache, LoggedInUser } from '../../components/Auth'; // Adjust the import path as necessary

const LogoutPage = () => {

    const [loggedOut, setLoggedOut] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Perform logout operations
        localStorage.removeItem('men_QT');
        AuthCache.setAuthStatus(false);
        LoggedInUser.setUsername(undefined);
        LoggedInUser.setAccountType(null);
        sessionStorage.removeItem('_acx');

        // Log the successful logout
        console.log("Logout successful");

        setLoggedOut(true);
        
    }, []); // Adding `navigate` as a dependency to the useEffect hook

    useEffect(() => {
        // Once loggedOut state is true, navigate to the login page
        if (loggedOut) {
            window.location.reload();
        }
    }, [loggedOut, navigate]); // Depend on `loggedOut` state to trigger navigation


    // Optionally return a message or a spinner
    return (
        <div>
            Logging out...
        </div>
    );
};

export default LogoutPage;