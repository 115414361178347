import React, { useEffect, useState } from 'react';
import { Box, Text } from "@chakra-ui/react";
import API from '../../services/apiRequest';
import { API_ENDPOINTS } from '../../services/apiConfig';
import { schemas } from '../../services/schemas';
import { useSchemas } from '../../services/core';
import HeavyTabs, { NiceTabs, TabContent } from '../../components/HeavyTabs'; 
import { DynamicForm, DynamicFormWithSelect } from '../../components/DynamicForm';


const AutopostContainer = () => {
    const { schemasData, loading, error } = useSchemas();
    const [autopostData, setAutopostData] = useState(null);
    const [autopostTemplateData, setAutopostTemplateData] = useState(null);

    const getAllAutoposts = async () => {
        await API.request(API_ENDPOINTS.getAllAutoposts).then(res => {
            const qdata = res.data;
            setAutopostData(qdata);
        });
    };
  
    const getAllAutopostsTemplate = async () => {
        await API.request(API_ENDPOINTS.getAllAutopostsTemplate).then(res => {
            const qdata = res.data;
            setAutopostTemplateData(qdata);
        });
    };


    useEffect(() => {
        getAllAutoposts();
        getAllAutopostsTemplate();
    }, []);


    return (
        <Box>
            <NiceTabs isFitted variant='enclosed'>
                    <TabContent label="Autoposts Menu">

                        <HeavyTabs layout={
                            [
                                // CREATE NEW AUTOPOST
                                {
                                    label: 'Create New Autopost',
                                    renderOn: schemasData,
                                    content: (
                                        <DynamicForm
                                        schema={ schemasData?.AutopostCreateModel }
                                        endpoint={ API_ENDPOINTS.createAutopost }
                                        success_msg='Autopost created!'
                                        updateCallback={ getAllAutoposts } 
                                        />)
                                },

                                // EDIT AUTOPOST
                                {
                                    label: 'Edit Autoposts',
                                    renderOn: autopostData,
                                    content: (
                                        <DynamicFormWithSelect
                                        select_data={autopostData}
                                        select_display_format={item => {
                                            const opt_format = item.commands.join(', ') ? item.commands : item.commands_template;
                                            return `${opt_format} | ${item.tickers.join(',')}`;
                                        }}
                                        select_display_property='commands'
                                        select_text='Select Autopost'
                                        schema={ schemasData?.AutopostModel }
                                        endpoint={ API_ENDPOINTS.updateAutopost }
                                        success_msg='Autopost updated!'
                                        usePathID={ true }
                                        removable={ API_ENDPOINTS.deleteAutopost }
                                        header_text='Delete this autopost?'
                                        submit_text='Delete'
                                        updateCallback={ getAllAutoposts } 
                                        />)
                                },
                                // HELP
                                {
                                    label: 'Help/Docs for Autopost',
                                    renderOn: true,
                                    content: (
                                        <Box>
                                            <Text>
                                                Documentation...
                                            </Text>
                                        </Box>)
                                }
                            ]
                        }/>
                    </TabContent>

                    <TabContent label="Autoposts Template Menu">
                    <HeavyTabs layout={
                            [
                                // CREATE NEW AUTOPOST TEMPLATE
                                {
                                    label: 'Create New Autopost Template',
                                    renderOn: schemasData,
                                    content: (
                                        <DynamicForm
                                        schema={ schemasData?.AutopostCommandTemplateModel }
                                        endpoint={ API_ENDPOINTS.createAutopostTemplate }
                                        success_msg='Autopost Template created!'
                                        updateCallback={ getAllAutopostsTemplate } 
                                        />)
                                },

                                // VIEW AUTOPOST TEMPLATE
                                {
                                    label: 'View my Autopost Template',
                                    renderOn: autopostTemplateData,
                                    content: (
                                        <DynamicFormWithSelect
                                        select_data={ autopostTemplateData }
                                        select_display_property='name'
                                        select_text='Select Autopost Template'
                                        schema={ schemasData?.AutopostCommandTemplateModel }
                                        />)
                                },

                                // EDIT AUTOPOST TEMPLATE
                                {
                                    label: 'Edit my Autopost Template',
                                    renderOn: autopostTemplateData,
                                    content: (
                                        <DynamicFormWithSelect
                                        select_data={ autopostTemplateData }
                                        select_display_property='name'
                                        select_text='Select Autopost Template'
                                        schema={ schemasData?.AutopostCommandTemplateModel }
                                        endpoint={ API_ENDPOINTS.updateAutopostTemplate }
                                        usePathID={ true }
                                        removable={ API_ENDPOINTS.deleteAutopostTemplate }
                                        header_text='Delete this autopost template?'
                                        submit_text='Delete'
                                        success_msg='Autopost Template updated!'
                                        updateCallback={ getAllAutopostsTemplate }
                                        />)
                                },

                                // HELP
                                {
                                    label: 'Help/Docs for Autopost',
                                    renderOn: true,
                                    content: (
                                        <Box>
                                            <Text>
                                                Documentation...
                                            </Text>
                                        </Box>)
                                }
                            ]
                        }/>
                    </TabContent>
                    
                </NiceTabs>
        </Box>
    )
  };
  
  export default AutopostContainer;