import API from "./apiRequest";
import { API_ENDPOINTS } from "./apiConfig";

export const schemas = {
    getSchema: async function(obj) {
        const res = await API.request(API_ENDPOINTS.openapiDocs);
        return res.data.components.schemas[obj];
    },
    getSchemas: async function() {
        let schemas = null;
        const res = await API.request(API_ENDPOINTS.openapiDocs).then(res => {
            schemas = res.data.components.schemas;
        });
        return schemas;
    },
    get: function(schema, obj) {
        return schema[obj];
    }
}