export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_ENDPOINTS = {
    login: { url: `${API_BASE_URL}/login`, method: 'post' },
    logut: { url: `${API_BASE_URL}/logout`, method: 'post' },

    register: { url: `${API_BASE_URL}/register`, method: 'post' },
    validateInviteToken: { url: `${API_BASE_URL}/api/validate_invite_token`, method: 'post' },

    //app configs
    updateEmailConfig: { url: `${API_BASE_URL}/config/gmail_client`, method: 'post' },

    authenticateGoogleAccount: { url: `${API_BASE_URL}/gapi/login-noreply-account`, method: 'get' },

    inviteUser: { url: `${API_BASE_URL}/api/invite`, method: 'post' },

    getAllUsers: { url: `${API_BASE_URL}/api/users/all`, method: 'get' },
    getCurrentUser: { url: `${API_BASE_URL}/api/user`, method: 'get' },
    passwordReset: { url: `${API_BASE_URL}/api/user/change_password`, method: 'put' },
    updateUser: { url: `${API_BASE_URL}/api/user`, method: 'put' },

    getAllUsersGlobalSettings: { url: `${API_BASE_URL}/api/users/user-global-settings`, method: 'get' },
    setAllUsersGlobalSettings: { url: `${API_BASE_URL}/api/users/user-global-settings`, method: 'post' },

    getAllCommands: { url: `${API_BASE_URL}/api/commands`, method: 'get' },
    createCommand: { url: `${API_BASE_URL}/api/commands`, method: 'post' },
    updateCommand: { url: `${API_BASE_URL}/api/commands`, method: 'put' },
    deleteCommand: { url: `${API_BASE_URL}/api/commands`, method: 'delete' },
    commandsDocs: { url: `${API_BASE_URL}/api/commands/docs`, method: 'get' },

    getServerCommandsForSelection: { url: `${API_BASE_URL}/api/commands/server/active-commands`, method: 'get' },
    toggleServerCommandUpdate: { url: `${API_BASE_URL}/api/commands/server/command-toggle`, method: 'post' },

    getAllAutoposts: { url: `${API_BASE_URL}/api/autopost`, method: 'get' },
    createAutopost: { url: `${API_BASE_URL}/api/autopost`, method: 'post' },
    updateAutopost: { url: `${API_BASE_URL}/api/autopost`, method: 'put' },
    deleteAutopost: { url: `${API_BASE_URL}/api/autopost`, method: 'delete' },

    getAllAutopostsTemplate: { url: `${API_BASE_URL}/api/autopost/template`, method: 'get' },
    createAutopostTemplate: { url: `${API_BASE_URL}/api/autopost/template`, method: 'post' },
    updateAutopostTemplate: { url: `${API_BASE_URL}/api/autopost/template`, method: 'put' },
    deleteAutopostTemplate: { url: `${API_BASE_URL}/api/autopost/template`, method: 'delete' },

    getMyDiscordServersRead: { url: `${API_BASE_URL}/api/discord/my_servers`, method: 'get' },
    getMyDiscordServersEdit: { url: `${API_BASE_URL}/api/discord/my_servers/edit`, method: 'get' },
    createDiscordServer: { url: `${API_BASE_URL}/api/discord/my_servers`, method: 'post' },
    updateDiscordServer: { url: `${API_BASE_URL}/api/discord/my_servers`, method: 'put' },
    deleteDiscordServer: { url: `${API_BASE_URL}/api/discord/my_servers`, method: 'delete' },

    getDiscordServersForAdminEdit: { url: `${API_BASE_URL}/api/discord/my_servers/admin`, method: 'get' },
    updateDiscordServersForAdminEdit: { url: `${API_BASE_URL}/api/discord/my_servers/admin/edit`, method: 'put' },

    getMyServerRolesRead: { url: `${API_BASE_URL}/api/discord/roles`, method: 'get' },
    createDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'post' },
    updateDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'put' },
    deleteDiscordRole: { url: `${API_BASE_URL}/api/discord/roles/delete`, method: 'delete' },

    getMyServerRolesRead: { url: `${API_BASE_URL}/api/discord/roles`, method: 'get' },
    createDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'post' },
    updateDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'put' },


    getMyServerRolesRead: { url: `${API_BASE_URL}/api/discord/roles`, method: 'get' },
    createDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'post' },
    updateDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'put' },


    getMyServerRolesRead: { url: `${API_BASE_URL}/api/discord/roles`, method: 'get' },
    createDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'post' },
    updateDiscordRole: { url: `${API_BASE_URL}/api/discord/roles`, method: 'put' },


    getMyServerStats: { url: `${API_BASE_URL}/api/stats/server_stats`, method: 'get' },
    openapiDocs: { url: `${API_BASE_URL}/openapi.json`, method: 'get' },

    createMessageQueue: { url: `${API_BASE_URL}/api/message/queue`, method: 'post' },
    getMessageQueue: { url: `${API_BASE_URL}/api/message/queue`, method: 'get' },

    updatediscordAppCreds: { url: `${API_BASE_URL}/api/discord/update_app_creds`, method: 'post' },
    addDiscordToServer: { url: `${API_BASE_URL}/api/discord/add_bot_via_oauth2`, method: 'post' },
};
