import React, { useState, useRef } from 'react';
// import DynamicTable from '../DynamicTable';
import { Stack, Container, Button, useToast, Text } from '@chakra-ui/react';
import { EmailIcon, DownloadIcon } from '@chakra-ui/icons'
import API from '../../services/apiRequest';
import { API_ENDPOINTS } from '../../services/apiConfig';

const RequestForm = ({ title="", selectable=false, children, disabled=false, message=null,
    ...props
}) => {
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const intervalRef = useRef(null);

    // To keep code DRY so I made a toasty function 
    const giveAToast = ({ title = "Success", description = "", status = "success" } = {}) => {
        toast({
            title: title,
            description: description,
            status: status,
            duration: 5000,
            isClosable: true,
            position: "top",
        });
    }

    // Convert JSON Object to CSV
    const convertToCSV = (objArray) => {
        if ( objArray.length === 0 ) {
            return 'No data available';
        }
        const array = [Object.keys(objArray[0])].concat(objArray).map((row) => {
            return Object.values(row)
            .map((value) => (
                Array.isArray(value) ? value.join(' ') : value)).join(',');
        }).join('\n');
        return array;
    };

    const downloadCSV = () => {
        if ( responseData && typeof responseData === 'object') {
            const title = Object.keys(responseData).find(key => Array.isArray(responseData[key]));
            const data = title ? responseData[title] : null;
        
            if ( data ) {
                const csv = convertToCSV(data);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;'});

                const currentDate = new Date().toISOString().slice(0, 10);
                const filename = `${title}_${currentDate}.csv`;

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            giveAToast({description:"CSV downloaded!"});
        }
    }

    const handleSubmit = async (e) => {
        setResponseData(null);
        if (!message) {
            giveAToast({
                title: "Error",
                description:"No message found",
                status: "error"
            });
            return;
        }
        const data = { action: message };
        await API.request(API_ENDPOINTS.createMessageQueue, data).then(res => {
            const status = res.data?.success ?? false;
            const tracker = res.data?.message_id;
            if (!status) {
                giveAToast({
                    title: "Error",
                    description:"An error has occured.",
                    status: "error"
                });
            } else { // Begin polling for a response
                setLoading(true);
                pollQBotResponse(tracker);
            } 
        }).catch(err => {
            const err_msg = err.response.data?.detail.detail ?? err.response.data?.detail ?? err.data?.detail ?? err.response.data?.detail.success ?? "An error has occured."; 

            giveAToast({
                title: "Error",
                description: err_msg ?? "An error has occured.",
                status: "error"
            });
        });
    };

    const pollQBotResponse = async (tracker) => {
        intervalRef.current = setInterval( async () => {
            await API.request(API_ENDPOINTS.getMessageQueue, null, tracker).then(res => {
                if (res.data.completed === 1) {
                    clearInterval(intervalRef.current);
                    setLoading(false);
                    setResponseData(res.data.response);
                }
            })
            .catch( err => {
                const err_msg = err.response.data?.detail.detail ?? err.response.data?.detail ?? err.data?.detail ?? err.response.data?.detail.success ?? "An error has occured."; 
            console.log("Error message: ", err)

            giveAToast({
                title: "Error",
                description: err_msg ?? "An error has occured.",
                status: "error"
            });
            clearInterval(intervalRef.current);
            setLoading(false);
        });
        }, 2000);
    }

    const handleCancel = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        setLoading(false);
        setResponseData(null);
    }

    return (
        <Container borderWidth='1px' borderRadius='lg' py={4}>
            <Text>{title}</Text>
            {children}
            {(disabled) &&(
                <>
                <Text>There is no content to download for this request</Text>
                <br/>
                </>
            )}

            {responseData && (
                <div>
                    <Text>MessageQueue Response:</Text>
                    <Container borderWidth='1px' borderRadius='lg' py={4}>
                    <code>{JSON.stringify(responseData, null, 2)}</code>
                    </Container>
                    <br />
                </div>
            )}
            <Stack direction="row" spacing={4}>
                <Button leftIcon={<EmailIcon />} isDisabled={disabled}
                    onClick={handleSubmit} colorScheme='blue'
                    isLoading={loading} loadingText='Waiting for Qbot Response'
                >Request</Button>
                { responseData && 
                    <Button leftIcon={<DownloadIcon/>} onClick={downloadCSV}
                        colorScheme='purple'
                    >Download CSV</Button>
                }
                { ( loading || responseData ) && 
                    <Button onClick={handleCancel} colorScheme='gray'>Cancel</Button> 
                }
                
            </Stack>
            
        </Container>
    );
}

export { RequestForm };